<template>
  <v-container class="success-view">
    <v-row align="center" justify="center" class="display-2 thanks">
      Thanks for signing up!
    </v-row>
    <v-row align="center" justify="center" class="display-1 thanks-description">
      You will be redirected to the application shortly.
    </v-row>
    <v-row align="center" justify="center">
      <img src="/img/logos/TP-Jobs-RGB@2x.png" alt="" />
    </v-row>
  </v-container>
</template>

<script>
  export default {
    mounted() {
      setTimeout(() => this.$router.push({path: '/'}), 2000);
    },
  };
</script>

<style scoped>
  .thanks {
    margin: 10%;
  }
  .thanks-description {
    margin: 10%;
  }
</style>
